/**
 * todo:
 * delete all VueJS
 * update to AlpineJS v3+
 * @type {{duration: number}}
 */

// default options
const defaultOptions = {
    promptMessage: null,
}

export default class UpdatePlugin {
    constructor(url) {
        this.url = url;
    }

    data(options = {}) {
        return {
            url: this.url,
            loading: false,
            options: Object.assign({}, defaultOptions, options), // options,
            init() {
                console.log('alert loading');
            },
            updatePlugin() {
                const promptMessage = this.options.promptMessage !== null ? this.options.promptMessage : 'Are you sure?';
                const userConfirmed = window.confirm(promptMessage);

                if (!userConfirmed) {
                    return;
                }

                this.loading = true;

                // send to endpoint
                fetch(this.url, {
                    method: 'GET',
                })
                    .then(response => {
                        console.log(response);

                        // collect response data (oke / failed) based on the http status code
                        return response.json().then(data => ({
                            error: !response.ok, // [MDN] the ok read-only property of the Response interface contains a boolean stating whether the response was successful (status in the range 200-299) or not.
                            status: response.status, // status code of the response (e.g., 200 for a success)
                            errors: typeof data.errors !== 'undefined' ? data.errors : null, // array with error messages (eg: Field X must be a number)
                            message: data.message, //
                            options: typeof data.options !== 'undefined' ? data.options : [],
                            data: typeof data.data !== 'undefined' ? data.data : [],
                        }))
                    })
                    .then(response => {
                        console.log(response);

                        if (response.error === true) {
                            return;
                        }

                        this.loading = false;
                    })
                    .catch((e) => {
                        // todo
                    })
                    .finally(() => {
                        /*setTimeout(() => {
                            this.isLoading(false);
                            this.toggleModal();
                        }, this.options.duration);*/
                    })
            }
        }
    }
}
