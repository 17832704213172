import {asset} from '@codinglabs/laravel-asset'
import moment from 'moment'

window.asset = asset;

// SVG Polyfill
(function(){
  var templates = document.querySelectorAll('svg template');
  var el, template, attribs, attrib, count, child, content;
  for (var i=0; i<templates.length; i++) {
    el = templates[i];
    template = el.ownerDocument.createElement('template');
    el.parentNode.insertBefore(template, el);
    attribs = el.attributes;
    count = attribs.length;
    while (count-- > 0) {
      attrib = attribs[count];
      template.setAttribute(attrib.name, attrib.value);
      el.removeAttribute(attrib.name);
    }
    el.parentNode.removeChild(el);
    content = template.content;
    while ((child = el.firstChild)) {
      content.appendChild(child);
    }
  }
})();

export default class FormResults {
	constructor(name) {
		window[name] = this.data()
	}
	data() {
		return {
			data: [],
			FormId: null,
			xhttp: null,
			Filters: [],
			tmpFilters: [],
			loading: true,
			contact_count: 0,
			unfiltered_contact_count: 0,
			StoredFiltersDropdown: false,
			StoredFiltersSelectedIndex: null,
			SingleContact: null,
			exportButtonText: 'PDF Exporteren',
			exportingPDF: false,
			bsr_contacts: [],
			bsr_average_contact: [],
			show_bsr_radar: false,
			loaded: false,
			StoredFilters: [
			{
				"name": "Mijn Filter",
				"filters" : [{
					'answer': 'Ik ben geïnteresseerd als familielid/vriend van degene die wil kopen',
					'answer_index': 2,
					'form_question_id': 4,
					'type': 'option'
				},
				{
					'answer': 'Ik ben geïnteresseerd als koper voor dit project',
					'answer_index': 1,
					'form_question_id': 4,
					'type': 'option'
				}]
			}, {
				"name": "Mijn Filter 2",
				"filters" : [{
					'answer': 'Ik ben geïnteresseerd als koper voor dit project',
					'answer_index': 1,
					'form_question_id': 4,
					'type': 'option'
				}]
			}
			],
			showBsrText: false,
			completed_contact_count: 0,

			init(FormId, Filters, SingleContact) {
				this.FormId = FormId
				this.tmpFilters = Filters;
				this.SingleContact = SingleContact;
                this.loading = true;
				var vm = this;
				this.makeRequest().then(function(responce){
					this.data = responce;
					this.OrigionalData = responce;
                    this.loading = false;
					vm.loaded = true;
				}.bind(this));
			},
			restoreFilters(){
				this.Filters = this.tmpFilters;
				this.tmpFilters = [];
				this.makeRequest().then(function(responce){
					this.data = responce;
					this.loading = false;
					this.loaded = true;
				}.bind(this));
			},
			makeRequest(){
				var vm = this;

				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/survey-results');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							vm.completed_contact_count = responce.completed_contact_count;
							delete responce.completed_contact_count;

							vm.contact_count = responce.contact_count
							delete responce.contact_count;

							vm.unfiltered_contact_count = responce.unfiltered_contact_count;
							delete responce.unfiltered_contact_count;
							vm.bsr_average_contact = responce.bsr_average_contact
							vm.bsr_contacts = responce.bsr_contacts
							delete responce.bsr_average_contact;
							delete responce.bsr_contacts;
							delete responce.completed_contact_count;
							resolve(responce)
						}
					}

					var ContactUid = typeof vm.SingleContact !== 'undefined' ? vm.SingleContact : null
					vm.xhttp.send(JSON.stringify({
						FormId: vm.FormId,
						Filters: vm.Filters,
						SingleContact: ContactUid
					}));
				});
			},
			resetFilters(){
				this.Filters = []
				this.StoredFiltersSelectedIndex = null
				this.StoredFiltersDropdown = false
				this.makeRequest().then(function(responce){
					this.data = responce;
					this.loading = false;
				}.bind(this));
			},
			toggleSelection(formQuestion, answerIndex){
				this.loading = true;
				this.tmpFilters = [];
				this.StoredFiltersSelectedIndex = null
				if( this.inFilters(formQuestion, answerIndex)){
					this.Filters.forEach((item, index) => {
						if(item.form_question_id == formQuestion.question_id && item.answer_index == answerIndex){
							this.Filters.splice(index, 1)
						}
					})
				}else{
					this.Filters.push( {'form_question_id' : formQuestion.question_id, 'answer_index': answerIndex, 'type': formQuestion.type, 'answer': String(formQuestion.labels[answerIndex]) } )
				}

				this.makeRequest().then(function(responce){
					this.data  = responce;
					this.loading = false
				}.bind(this));
			},
			inFilters(formQuestion, answer){
				return this.Filters.filter(function (e) {
					return e.form_question_id == formQuestion.question_id && e.answer_index == answer
				}).length ? 1 : 0;
			},
			ToggleStoredFiltersDropdown(){
				this.StoredFiltersDropdown ^= true
			},
			setStoredFilter(index){
				this.StoredFiltersSelectedIndex = index
				this.Filters = this.StoredFilters[index].filters.slice()
				this.StoredFiltersDropdown = false
				this.makeRequest().then(function(responce){
					this.data  = responce;
					this.loading = false
				}.bind(this));
			},
			prepareRequest(method, url){
				var vm = this
				vm.xhttp = new XMLHttpRequest();
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				vm.xhttp.open(method, url, true);
				vm.xhttp.setRequestHeader('Content-Type', 'application/json');
				vm.xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
			},
			getSource(formQuestion, answerIndex){
				if(formQuestion.images == null){
					return false;
				}
				return formQuestion.images[answerIndex]
			},
			getBrsColor(formQuestion, answerIndex){
				if(formQuestion.bsr_colors == null){
					return false;
				}
				// We have any bsr question
				this.showBsrText = true;
				return formQuestion.bsr_colors[answerIndex]
			},
			exportPdf(postUrl){
				var vm = this;
				vm.exportingPDF = true
				vm.exportButtonText = 'Even geduld a.u.b.'

				vm.prepareRequest('post', postUrl);
				vm.xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						vm.downloadFile(responce.url)
					}
				}
				vm.xhttp.send(JSON.stringify({
					FormId: vm.FormId,
					Filters: vm.Filters,
				}));
			},

			downloadFile(urlToSend) {
				var vm = this
				var req = new XMLHttpRequest();
				req.open("GET", urlToSend, true);
				req.responseType = "blob";
				req.onload = function (event) {
					vm.exportButtonText = 'PDF Exporteren'
					vm.exportingPDF = false
					moment.locale('nl')
					var filename = 'WoonMens Export - ' + moment().format('LLL') + '.pdf';
					var blob = req.response;
					var link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = filename
					link.click();
				};
				req.send();
			},
			log(toLog){
				console.log(toLog);
				return toLog
			},
            getMediaFileUrl(mediaId){
                console.log(mediaId)
                var vm = this;

                vm.prepareRequest('post', '/get-media-url');
                vm.xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        const response = JSON.parse(this.responseText)
						window.open(response.data.url, '_blank')
                    }
                }
                vm.xhttp.send(JSON.stringify({
                    mediaId: mediaId,
                }));
            },
            openSignature(value){
                if(this.validURL(value)){
                    return window.open(value, '_blank');
                }
                const image = new Image();
                image.src = value;

                const w = window.open("");
                w.document.write(image.outerHTML);
            },
            validURL(str) {
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(str);
            }
		}
	}
}
